'use client'

import { Button } from '@vinted/web-ui'
import { MouseEvent } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { usePageId } from '@marketplace-web/shared/request'
import { toUrlQuery } from '@marketplace-web/shared/utils'

import { clickEvent } from '_libs/common/event-tracker/events'
import { SIGNUP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useRefUrl from 'hooks/useRefUrl'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

import useAuthModal from '../../hooks/useAuthModal/useAuthModal'
import { getClientSelectTypeUrl } from '../../utils/url'

const HeaderLoginButton = () => {
  const refUrl = useRefUrl()
  const translate = useTranslate()
  const { track } = useTracking()
  const { openAuthModal } = useAuthModal()
  const pageId = usePageId()
  const isNonNativeFlowAbTestEnabled = useAbTest('non_native_flow')?.variant === 'on'
  const isAuthPageOpen = pageId === 'member-signup-select-type'

  function handleClick(event: MouseEvent) {
    track(clickEvent({ target: ClickableElement.Login }))

    if (isAuthPageOpen) return

    event.preventDefault()
    openAuthModal()
  }

  return (
    <Button
      text={translate('header.actions.login')}
      size={Button.Size.Small}
      url={`${isNonNativeFlowAbTestEnabled ? getClientSelectTypeUrl() : SIGNUP_URL}?${toUrlQuery({ ref_url: refUrl })}`}
      onClick={handleClick}
      testId="header--login-button"
    />
  )
}

export default HeaderLoginButton
