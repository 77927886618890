'use client'

import { useCallback, useMemo, useState } from 'react'

import { useSession } from '@marketplace-web/shared/session'
import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { navigateToPage } from '@marketplace-web/shared/browser'

import AuthModalWithProvider from '../../components/AuthModal'
import { AuthModalContext } from './AuthModalContext'
import { getClientSelectTypeUrl } from '../../utils/url'

enum AuthModalState {
  Standard = 'standard',
  Business = 'business',
  Closed = 'closed',
}

type Props = {
  children: React.ReactNode
}

const AuthModalProvider = ({ children }: Props) => {
  const userId = useSession().user?.id
  const [authModalState, setAuthModalState] = useState(AuthModalState.Closed)
  const isNonNativeFlowAbTestEnabled = useAbTest('non_native_flow')?.variant === 'on'

  const openAuthModal = useCallback(
    ({ isBusiness }: { isBusiness?: boolean } = {}) => {
      if (!isBusiness && isNonNativeFlowAbTestEnabled) {
        navigateToPage(getClientSelectTypeUrl())

        return
      }

      setAuthModalState(isBusiness ? AuthModalState.Business : AuthModalState.Standard)
    },
    [isNonNativeFlowAbTestEnabled],
  )

  const closeAuthModal = useCallback(() => {
    setAuthModalState(AuthModalState.Closed)
  }, [])

  const contextValue = useMemo(() => {
    return {
      openAuthModal,
      closeAuthModal,
      isAuthModalOpen: authModalState !== AuthModalState.Closed,
      isBusinessAuth: authModalState === AuthModalState.Business,
    }
  }, [openAuthModal, closeAuthModal, authModalState])

  return (
    <AuthModalContext.Provider value={contextValue}>
      {children}
      {!userId && <AuthModalWithProvider />}
    </AuthModalContext.Provider>
  )
}

export default AuthModalProvider
